<template>
  <div class="modal">
    <div class="backdrop" @click="close"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="close">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <div class="row">
            <p class="title">TERMOS E CONDIÇÕES PARA OS TOMADORES E INVESTIDORES</p>
          </div>
          <div class="p-4 md:mt-6">
         <ul>
    <li class="title">1. QUEM SOMOS</li>
    <li>1.1. Tutu Digital Tecnologia Ltda é uma plataforma online que permite a realização de investimentos e financiamentos de parte a parte (peer-to-peer lending), por meio da aproximação de investidores (“Investidor(es)”) e tomadores de empréstimos (“Tomador(es)”
        e, em conjunto com os Investidores, (“Usuários”), de modo que os primeiros possam atender a demanda de crédito dos segundos (“Plataforma Tutu”).</li>
    <li>1.2. A Plataforma Tutu não realiza as operações de empréstimo e/ou investimento diretamente, atuando como intermediadora e procuradora dos Usuários (i) na contratação de empréstimos perante a instituição financeira parceira em favor dos Tomadores,
        mediante investimento pelos Investidores e (ii) na aquisição de títulos de investimento (Certificados de Depósitos Bancários – CDBs ou Cédulas de Crédito Bancário – CCBs) pelos Investidores, sendo essas operações realizadas no âmbito do mercado
        de crédito.</li>
    <li>1.3. O Usuário deverá cumprir com os termos e condições da Plataforma Tutu e com os seguintes requisitos necessários para o estabelecimento da relação contratual (“Termos”):</li>
    <li>a) ser maior de 18 (anos) anos;</li>
    <li>b) residir em território brasileiro; e</li>
    <li>c) estar devidamente inscrito no Cadastro de Pessoas Físicas ou Cadastro Nacional de Pessoas Jurídicas do Ministério da Economia (CPF/ME ou CNPJ/ME).</li>
    <li>1.4. Para determinados investimentos na Plataforma Tutu, o Investidor deverá, eventualmente, (i) atender aos critérios para sua qualificação de investidor qualificado (ou profissional), nos termos da Resolução CVM nº 30 de 11 de maio de 2021; e (ii)
        atender aos critérios para sua qualificação de Investidor, como aos critérios de suitability (adequação de perfil) da Plataforma Tutu.</li>
    <li>Maiores informações estão disponibilizadas na página de acesso www.tutudigital.com.br, inclusive por meio do nosso FAQ disponível em https://www.tutudigital.com.br/investimento.</li>

</ul>
<ul>
    <li class="title">2. INTRODUÇÃO</li>
    <li>2.1. A TUTU DIGITAL TECNOLOGIA LTDA. ou à TD CORRESPONDENTE BANCÀRIO LTDA. (“Tutu”) poderá ser referida nestes Termos como "nós" e "nosso".Os Usuários poderão ser referidos como “você”, seja como indivíduo ou em nome de uma entidade que preencha os
        critérios para Tomadores ou Investidores, estabelecidos nos itens 3 e 4 abaixo. Da mesma forma, as referências a “Serviços”, "Sistemas", "Plataforma" ou “Site”, remetem a um dos serviços prestados por nós. As referências a "você" e "seu" remetem
        ao usuário da Plataforma,</li>
    <li>2.2 Caso você decida se tornar um Usuário da Plataforma Tutu como Tomador ou Investidor, será necessário o cumprimento destes Termos.</li>
    <li>2.3. Assim, pedimos a gentileza de que estes Termos sejam devidamente lidos e compreendidos, bem como nossos Termos e Condições de Uso e Política de Privacidade.</li>
    <li>2.4. Ao usar nossa Plataforma e os serviços prestados por por meio dela ou de quaisquer outros sistemas fornecidos pela Plataforma Tutu, você deverá, necessariamente, confirmar que leu, entendeu e concordou com estes Termos, bem como com os Termos
        e Condições de Uso e com a Política de Privacidade (cada qual conforme alterada de tempos em tempos), assim como quando estiver agindo em nome de uma sociedade anônima, sociedade limitada, sociedade simples ou outra entidade separada. Se você
        não concorda com estes termos e condições, você deve parar de usar esta Plataforma imediatamente.</li>
    <li>2.5. Ao aceitar estes Termos, bem como os Termos e Condições de Uso e Política de Privacidade aplicáveis à Plataforma Tutu, você declara e garante que é maior de 18 (dezoito) anos e é responsável pelos atos realizados na Plataforma Tutu.</li>
    <li>2.6. Se você não tiver menos de 18 (dezoito) anos de idade, você deve parar de usar a Plataforma Tutu imediatamente.</li>
    <li>2.7. Cada realização de empréstimo e investimento deve respeitar as condições de crédito e os termos do contrato celebrado entre Tomador e a instituição financeira parceira da Plataforma Tutu ("Contrato de Empréstimo"). O Contrato de Empréstimo é
        um acordo separado e é regido por termos e condições independentes, o qual poderá ser formalizado pela Tutu agindo por conta e ordem do Investidor, conforme previamente autorizado por meio da Plataforma.</li>
    <li>2.7.1. Nesse ponto, a Tutu tem poderes e capacidade de atuar em nome dos Investidores em relação aos empréstimos e investimentos, bem como para executar eventuais débitos decorrentes de inadimplemento destes Termos por parte dos Tomadores.</li>
    <li>2.8. Se você, ao atuar em nome de uma sociedade anônima, sociedade limitada, sociedade simples, MEI ou qualquer outra pessoa jurídica, utilizar esta Plataforma ou celebrar um Contrato de Empréstimo, deverá declarar e garantir que está devidamente
        autorizado e possui poderes para agir em nome da respectiva pessoa jurídica.</li>

</ul>
<ul>
    <li class="title">3. COMO SE TORNAR UM TOMADOR</li>
    <li>3.1. Para ser um Tomador na Plataforma Tutu, você deve atender aos seguintes critérios:</li>
    <li>(a) possuir uma empresa devidamente cadastrada no Cadastro Nacional de Pessoas Jurídicas do Ministério da Economia (CNPJ/ME), que se encontre em plena atividade econômica há pelo menos 15 (quinze) meses, bem como possua sede e representante legal
        com domicílio no país;</li>
    <li>(b) Indicar um endereço válido, devendo mantê-lo atualizado. Para tanto, a Plataforma Tutu poderá, a qualquer tempo, solicitar o envio de comprovante de endereço atual em nome da Tomadora. Eventuais citações, no âmbito de processos judiciais, direcionadas
        ao endereço informado presumir-se-ão válidas.</li>
    <li>(c) disponibilizar uma conta bancária para ser usada em suas movimentações financeiras relacionadas com a Plataforma Tutu;</li>
    <li>(d) a empresa Tomadora, seus diretores, administradores, sócios ou proprietários devem atender a critérios mínimos de risco de crédito e de prevenção a fraude, que incluem a obtenção de uma pontuação mínima de agências de referência de crédito, tais
        como Equifax ou Experian, de acordo com a cláusula 3.2 e 3.3 abaixo, com o que você, desde já, concorda.</li>
    <li>(e) registrar e apresentar detalhes de seu negócio para publicação no site www.tutudigital.com.br. Favor notar que alguns (mas não todos) desses detalhes serão divulgados para os Investidores, de acordo com a cláusula 5.1 abaixo, com o que você, desde
        já, concorda.</li>
    <li>3.2 Como parte do processo de cadastro, vamos precisar confirmar a sua identidade e a da empresa, bem como as pessoas autorizadas a agir em seu nome. Nós vamos fazer isso usando agências de referência de crédito, tais como Equifax ou Experian. Caso
        seja necessário, podemos requisitar o envio de cópias de seus documentos de identificação, da empresa e seus representantes, bem como quaisquer outros que venham a ser necessários. Se os documentos de identificação não forem devidamente submetidos,
        infelizmente, você não poderá contratar empréstimos na Plataforma Tutu.</li>
    <li>3.3. Uma vez que você tiver se cadastrado e solicitado empréstimo na Plataforma Tutu, nós iremos analisar seu crédito e os procedimentos antifraude e de prevenção à lavagem de dinheiro, tanto em relação à empresa quanto a você enquanto proprietário,
        sócio ou diretor/administrador, usando agências de referência de crédito, tais como Equifax ou Experianá. A Tutu usa suas próprias diretrizes e políticas na avaliação dos pedidos de Empréstimo, bem como as diretrizes e políticas da instituição
        financeira parceira.</li>
    <li>3.4. Você declara e garante à Plataforma Tutu que todas as informações fornecidas a nós no curso do processo de aplicação e empréstimo são verdadeiras e precisas em todos os aspectos. Você irá nos atualizar sobre a mudança de qualquer uma das informações
        já fornecidas.</li>
    <li>3.5. Você declara e garante ter nos revelado quaisquer circunstâncias que possam resultar na mudança de sua condição financeira ou do seu negócio (incluindo, sem limitar, quaisquer reclamações, passivos ocultos, litígios, arbitragem, processos judiciais,
        administrativos ou investigações ).</li>
    <li>3.6. Você concorda em fornecer, o mais brevemente possível, informações e documentos completos para a Plataforma Tutu , caso você tome ciência ou suspeite que alguma das circunstâncias previstas na cláusula 3.5 ocorreram ou possam ocorrer. Além disso,
        você deverá fornecer à Plataforma Tutu as informações acerca de sua condição financeira, negócios e assuntos comerciais de tempos em tempos.</li>
    <li>3.7. Em caso de ciência de qualquer processo administrativo/judicial, atual ou futuro, da Receita Federal do Brasil ou de outra entidade pública contra você, você não poderá aceitar um empréstimo a menos que tenha prestado à Plataforma Tutu todas
        as informações acerca do processo e a Plataforma Tutu tenha acordado com o oferecê-lo.</li>
    <li>3.8. Outras informações também podem ser requisitadas, conforme disposto nos Termos e Condições de Uso, disponível no link www.tutudigital.com.br.</li>

</ul>
<ul>
    <li class="title">4. COMO SE TORNAR UM INVESTIDOR</li>
    <li>4.1. Para se tornar um Investidor, você pode ser pessoa física ou jurídica.</li>
    <li>(i) Investidor Pessoa Física deve atender aos seguintes critérios:</li>
    <li>(a) ser maior de 18 (dezoito) anos;</li>
    <li>(b) residir em território brasileiro;</li>
    <li>(c) estar devidamente inscrito no Cadastro de Pessoas Físicas (CPF/ME);</li>
    <li>(d) indicar uma conta bancária para fazer os aportes e resgastes dos investimentos; e</li>
    <li>(e) ao se cadastrar na Plataforma Tutu, abrir uma conta de pagamento (gerida por instituição de pagamento parceira da Tutu) para operacionalizar os investimentos.</li>
    <li>(ii) Investidor Pessoa Jurídica deve atender aos seguintes critérios:</li>
    <li>(a) possuir uma empresa devidamente cadastrada no Cadastro Nacional de Pessoas Jurídicas do Ministério da Economia (CNPJ/ME), que se encontre em plena atividade econômica, possua sede e representante legal com domicílio no país;</li>
    <li>(b) disponibilizar uma conta bancária para fazer os aportes e resgastes dos investimentos; e</li>
    <li>(c) ao se cadastrar na Plataforma Tutu, abrir uma conta de pagamento (gerida por instituição de pagamento parceira da Tutu) para operacionalizar os investimentos.</li>
    <li>4.2. Para determinados investimentos na Plataforma Tutu, o Investidor deverá, eventualmente, atender aos critérios para sua qualificação de Investidor, como aqueles de suitability (adequação de perfil) da Plataforma Tutu.</li>
    <li>4.3. Além disso, para determinados investimentos na Plataforma Tutu, o Investidor deverá, eventualmente, atender aos critérios para sua qualificação de investidor qualificado (ou profissional), nos termos da Resolução CVM nº 30 de 11 de maio de 2021.</li>
    <li>4.4. O Investidor declara que os aportes realizados nas contas de investimento são regulares, respondendo individualmente por qualquer infração ou crime associado à lavagem de dinheiro ou financiamento ao terrorismo.</li>
    <li>4.5. O Investidor declara e concorda que a Tutu tem poderes para, em nome do Investidor, atuar em juízo, sempre que necessário, para buscar a satisfação dos investimentos realizados no casos em que os Tomadores restarem inadimplente, não existindo
        dúvidas quanto aos poderes concesidos e à legitimidade processual da Plataforma Tutu.</li>
    <li>4.6. Outras informações também podem ser requisitadas, conforme disposto nos Termos e Condições de Uso, disponível no link www.tutudigital.com.br.</li>

</ul>
<ul>
    <li class="title">5. NOME DE USUÁRIO E SENHA</li>
    <li>5.1. Em seu cadastro, você deverá, a fim de acessar áreas restritas da Plataforma Tutu, fornecer os seguintes dados: endereço completo, endereço de e-mail, nome de usuário, senha pessoal. Este cadastro será confirmado por e-mail. Cada vez que você
        acessar sua conta de Investidor ou Tomador na Plataforma Tutu, você precisará digitar seu nome de usuário e senha, que são intransferíveis (salvo consentimento prévio e por escrito da Tutu).</li>
    <li>5.2. O seu nome de usuário, senha e a confirmação por e-mail são os métodos que utilizamos para identificar você e por isso você deve mantê-los seguros. Você é responsável por todas as informações e atividades na Plataforma, independentemente da pessoa
        que estiver usando seu nome de usuário e senha. Qualquer violação de segurança, perda, roubo ou uso não autorizado de um nome de usuário, senha ou informações de segurança, devem ser notificados imediatamente através dos contatos indicados na
        Cláusula 18 abaixo.</li>
    <li>5.3. Você concorda em não adaptar ou contornar os sistemas existentes em conexão com a Plataforma.</li>
    <li>5.4. Reservamo-nos o direito de não atender suas instruções no caso de suspeitarmos que a pessoa logada em sua conta de Investidor ou Tomador Tutu não é você, bem como no caso de suspeitarmos de atividade ilegal ou fraudulenta ou de uso não autorizado.</li>

</ul>
<ul>
    <li class="title">6. O PROCESSO DE EMPRÉSTIMO</li>
    <li>6.1. A Tutu poderá exigir do Tomador, de seus sócios e/ou administradores da empresa garantias pessoais ou reais (e.g., aval, fiança, penhor ou alienação fiduciária) antes dos fundos serem liberados pela instituição financeira parceira para sua utilização.
        Nesse caso, a garantia será formalizada na própria CCB ou em instrumento separado.</li>
    <li>6.2. Após realização de procedimento de análise de crédito, de prevenção à lavagem de dinheiro e antifraude, será atribuído uma determinada avaliação ao crédito do Tomador (“Classificação”), por meio da qual será aferida a capacidade do Tomador de
        pagamento de eventual empréstimo a ser contraído, bem como os riscos oriundos da operação. A Classificação de risco atribuída pela Tutu a cada Tomador se baseia em (i) informações incluídas na própria requisição de Empréstimo; (ii) resultados
        obtidos a partir de verificação on-line feita com agências de referência de crédito, tais como Creditsafe, Equifax ou Experian; e (iii) algoritmos e outros mecanismos do modelo de crédito desenvolvido pela Plataforma Tutu. A partir de tais procedimentos,
        o Empréstimo poderá ou não ser aprovado, sendo que, no caso de aprovação, será atribuída determinada taxa de juros aplicável ao seu Empréstimo, conforme parâmetros estabelecidos pela Plataforma.</li>
    <li>6.3. Na sequência da avaliação acima, a requisição de Empréstimo do Tomador será disponibilizada na Plataforma Tutu, para os Investidores possam manifestar seu eventual interesse em investir recursos. Para tanto, determinados dados do Tomador e da
        empresa do Tomador serão disponibilizados na Plataforma no âmbito da requisição de Empréstimo.</li>
    <li>6.4. O Tomador concorda em permitir que o pedido de Empréstimo seja analisado, avaliado e disponibilizado na Plataforma para terceiros de boa fé, no âmbito da nossa relação com os Investidores na Plataforma.</li>
    <li>6.5. Uma vez que a requisição de Empréstimo pela Tomadora seja postada na Plataforma, o processo de formação de investimento irá começar e potenciais Investidores irão manifestar interesse em conferir parte ou a totalidade do valor que o Tomador solicitou
        para seu Empréstimo, o qual será lastreado em Cédula(s) de Crédito Bancário (“CCBs”) condicionada(s). Caso o valor mínimo informado na requisição de Empréstimo seja subscrito por Investidores na Plataforma, as CCBs terão atingido a condição para
        sua validade plena, sendo então ratificadas pela instituição financeira parceira e os recursos obtidos serão transferidos para a conta do Tomador.</li>
    <li>6.6. Os Empréstimos que não tiverem sido subscritos durante o processo de formação de investimento acima referido serão retirados da página de investimentos da Plataforma e a emissão das CCBs será cancelada pela instituição financeira parceira em
        2 (dois) dias úteis, a contar do término do prazo. Nesse caso, o Tomador não terá qualquer obrigação adicional advinda de sua requisição de Empréstimo. Os juros só serão acumulados a partir da data em que o processo de formação de investimento
        se efetivar e os valores refletidos nas CCBs forem transferidos pela instituição financeira parceira ao Tomador.</li>
    <li>6.7. O prazo para o processo de formação de investimento expirará no que ocorrer primeiro entre (i) a data de subscrição de um Empréstimo totalmente financiado e (ii) o prazo de 21 (vinte um) dias corridos após a data de veiculação do investimento
        na Plataforma (salvo se acordado de outro modo pela Tutu, a seu exclusivo critério).</li>
    <li>6.8. As informações na página de requisição de Empréstimo poderão ser editadas a qualquer momento antes do início do processo de formação de investimento. Após o começo do processo de formação de investimento, o Tomador não poderá alterar a página
        de requisição de Empréstimo até o fim do processo de formação de investimento ou até o momento em que a totalidade do empréstimo seja quitada.</li>
    <li>6.9. A função principal da Tutu é intermediar a relação entre Tomadores e Investidores, a fim de reuni-los, fornecendo processo adequado para a celebração de empréstimos com a instituição financeira parceira (incluindo o desenvolvimento de acordos
        de forma de empréstimo padrão) em Plataforma Digital e para facilitar o pagamento e cobrança de valores devidos em relação a esses Empréstimos (incluindo certas ações limitadas em caso de descumprimento do Tomador, conforme estabelecido nestes
        Termos).
    </li>
    <li>6.10. Embora a Tutu agregue valor à análise de crédito como parte de sua atuação, a Classificação não pretende ser informativa ou vinculativa, de forma que a Tutu não assume qualquer responsabilidade por qualquer informação sobre um Tomador que seja
        disponibilizada aos potenciais Investidores por meio da Plataforma ou pela classificação de crédito dos Tomadores, sendo que o Investidor formará a sua própria opinião sobre a qualidade do crédito de um Tomador e realizará a sua própria análise
        e avaliação para cada Empréstimo. Ou seja, cabe ao Investidor a decisão e responsabilidade sobre o Investimento realizado para fins de empréstimo ao Tomador.</li>
    <li>6.11. Para todos os Empréstimos, o processo de formação de investimento ficará aberto e disponível para manifestação de interesse dos Investidores até às 18 (dezoito) horas do 21º (vigésimo primeiro) dia corrido, contados do dia em que o referido
        processo. A Tutu poderá, a seu exclusivo critério, prorrogar este prazo, oportunidade em que o processo de formação de investimento permanecerá em aberto e disponível para manifestações de interesse por parte dos Investidores.</li>
    <li>6.12. Após ter sido atingido o valor mínimo de financiamento e o processo de formação do investimento ter sido bem sucedido, o Tomador desde já acorda em outorgar poderes à Tutu para agir por sua conta e ordem e celebrar Contrato de Empréstimo separado
        e juridicamente vinculativo perante a instituição financeira parceira, por meio da Plataforma, e com cada um dos Investidores. Da mesma forma, após a realização do Investimento, os Investidores outorgam poderes à Tutu para agir em juízo, ou fora
        dele, para a defesa de seus interesses em relação ao Investimento, especialmente nos casos em que os Tomadores restarem inadimplentes.</li>
    <li>6.13. Após o Contrato de Empréstimo ser gerado automaticamente pela Plataforma Tutu, os recursos relativos ao Empréstimo serão então transferidos para a conta bancária indicada pelo Tomador, sendo que tais valores poderão posteriormente ser sacados
        ou transferidos para outra conta bancária de sua escolha. Ressaltamos que, depois de transcorrido o processo de formação do investimento, o Empréstimo não poderá mais ser cancelado por qualquer motivo (embora, para que não restem dúvidas, este
        poderá ser repago em sua totalidade em qualquer momento).</li>
    <li>6.14. Cada Empréstimo terá o período de validade especificado no respectivo Contrato de Empréstimo gerado pela Plataforma Tutu, o qual será encaminhado aos e-mails cadastrados na Plataforma em até 24 (vinte e quatro) horas a contar do fechamento da
        operação.
    </li>
    <li>6.14. Não obstante qualquer outra cláusula destes Termos, em circunstâncias excepcionais e em sua absoluta discricionariedade, a Tutu (atuando como agente de garantia e procurador dos Investidores) pode concordar com o Tomador para reestruturar o
        Empréstimo e alterar o Contrato de Empréstimo para aumentar o prazo, com a possibilidade de alteração correspondente na taxa de juros. Para evitar dúvidas, o Tomador reconhece e concorda que a Tutu poderá exigir-lhe documentação complementar e/ou
        atualizada como condição para reestruturação do Empréstimo.</li>
    <li>6.14.1. O Investidor concorda, desde já, em outorgar poderes à Tutu para que esta atue como sua mandatária: (a) na reestruturação do Empréstimo e na alteração do Contrato de Empréstimo, desde que tal reestruturação e/ou alteração seja necessária e
        que a Tutu atue, de boa-fé, a fim de buscar eficiência e rentabilidade ao investimento realizado; e (b) na realização de novos investimentos a serem realizados por meio do saldo mantido na conta de pagamento do Investidor, gerida por instituição
        de pagamento parceira da Tutu, desde que o Investidor concorde expressamente. O Investidor concorda que poderá revogar os poderes outorgados nesta Cláusula a qualquer momento, mediante simples solicitação à Tutu.</li>
    <li>6.15. Se e na medida em que a Tutu se comprometer a reestruturar o Empréstimo de acordo com a cláusula 6.14, esta poderá cobrar uma taxa do Tomador, que será comunicada em cada operação.</li>
    <li>6.16. O Tomador concorda que os recursos advindos do Empréstimo serão utilizados exclusivamente para os fins declarados em sua requisição de Empréstimo.</li>
</ul>
<ul>
    <li class="title">7. CARTEIRA AUTOMÁTICA</li>
    <li>7.1. O Investidor poderá optar por colocar seus recursos na carteira automática (“Carteira Automática”), opção disponibilizada na Plataforma Tutu, tendo, por conseguinte, um investimento automático. Todos os recebimentos ou saldos do Investidor na
        Plataforma Tutu serão reinvestidos de maneira automática, o qual o Investidor, ao fazer esta opção, desde já concorda.</li>
    <li>7.3. O Investidor não precisará ter valores em sua conta para ativar a Carteira Automática, bastando transferir os recursos antes de cada rodada para que os investimentos sejam alocados. Nenhum investimento será efetivado sem que haja saldo suficiente
        na conta do Investidor.</li>
    <li>7.2. Os investimentos realizados por meio da Carteira Automática observarão o perfil de Investidor selecionado (suitability): podendo ser este classificado como arrojado, balanceado ou conservador. Isso poderá impactar nos investimentos e reinvestimentos
        automáticos. O perfil poderá ser reanalisado a qualquer momento, conforme for o caso, o que poderá resultar na mudança do perfil.</li>
    <li>7.3. O Investidor será o único responsável pelos investimentos advindos da Carteira Automática, devendo selecionar os ratings de risco de maneira consciente.</li>
    <li>7.4. O Investidor poderá desativar a Carteira Automática a qualquer tempo, podendo reativá-la, igualmente, a qualquer tempo.</li>
</ul>
<ul>
    <li class="title">8. TAXAS APLICÁVEIS</li>
    <li>8.1. Se o Tomador contratar um Empréstimo por meio da Plataforma, será cobrada:</li>
    <li>(a) uma taxa de assessoria devida à Tutu, sobre o valor emprestado, a qual será subtraída diretamente pela instituição financeira parceira do valor a ser repassado ao Tomador;</li>
    <li>(b) uma taxa de juros remuneratórios devida aos Investidores, pactuada no respectivo Empréstimo; e</li>
    <li>(c) eventuais outras taxas cobradas pela instituição financeira parceira da Tutu, a serem acordadas previamente com o Tomador.</li>
    <li>8.2. Assim, após as deduções das taxas acima, bem como dos impostos aplicáveis, o Tomador receberá o montante líquido do valor emprestado, conforme previamente informado na Plataforma.</li>
    <li>8.3. A Tutu poderá optar por dispensar ou reduzir as taxas indicadas acima ao longo do tempo.</li>
    <li>8.4. As taxas acima contemplam a remuneração de Tutu, dos Investidores e todos os demais agentes envolvidos.</li>
    <li>8.5. Ao subscrever parcial ou totalmente o valor requisitado para o Empréstimo por um Tomador por meio da Plataforma, será cobrada do Investidor uma taxa à alíquota de 4% (quatro por cento) sobre o valor do aporte, taxa essa que será deduzida, pela
        Plataforma Tutu, do montante da remuneração pagável ao Investidor em decorrência do aporte de recursos para o Empréstimo.</li>
</ul>
<ul>
    <li class="title">9. PAGAMENTO DO EMPRÉSTIMO</li>
    <li>9.1. Os pagamentos serão estabelecidos no Contrato de Empréstimo e serão efetuados na conta de pagamento do Investidor, gerida por instituição de pagamento parceira da Tutu, aberta quando do cadastro do Investidor na Plataforma Tutu.</li>
    <li>9.2. Os pagamentos relativos às parcelas devidas do Empréstimo, bem como respectivas especificações, serão enviados mensalmente aos Tomadores por e-mail e podem ser requisitados, a qualquer momento, pelo e-mail quero@tutudigital.com.br. O Tomador
        poderá efetuar o pagamento do Empréstimo inteiro, em qualquer momento, sem custo adicional, desde que pague o valor principal em aberto, juntamente com os juros devidos, até a data em que o Empréstimo for repago.</li>
    <li>9.3. A conta de pagamento do Investidor poderá realizar e receber transferências de recursos de forma instantânea por meio do PIX, o qual será operacionalizado por meio da instituição de pagamento parceira da Tutu.</li>
</ul>
<ul>
    <li class="title">10. GARANTIAS</li>
    <li>10.1. A Tutu poderá lhe solicitar a outorga de garantias a depender de sua classificação de crédito. Eventuais garantias serão previamente acordadas com base em parâmetros condizentes ao empréstimo contratado e poderão ser previstas na própria CCB
        ou em instrumento de garantia apartado.</li>
    <li>10.2. Em caso de serem indicados avalistas nos Contratos de Empréstimos, todos estes são obrigados a assinar o contrato, ficando ciente que poderão responder pelas dívidas dos Tomadores.</li>
</ul>
<ul>
    <li class="title">11. OUTORGA DE PODERES</li>
    <li>11.1. Você, desde já, nomeia a Tutu como sua procuradora com poderes especiais para, em seu nome e por sua conta, negociar e obter crédito perante instituições financeiras, assinar contratos de empréstimos, abrir conta (corrente ou de pagamento) para
        movimentar os valores financiados, movimentar a conta aberta para tal finalidade, acertar prazos, juros e ônus da dívida, repactuar taxas de juros, emitir títulos representativos do débito perante as instituições financeiras, ou ainda, substabelecer
        em todo ou em parte o mandato outorgado, conforme sua condição de Investidor ou Tomador.</li>
    <li>11.2. Tendo em vista o papel da Tutu de intermediadora, conforme elucidado no item 6.9, ela terá legitimidade ativa para propor eventuais ações de execução de título extrajudicial em face dos Tomadores. Em outras palavras, atuando a Tutu em nome das
        partes interessadas, fica-lhe outorgado mandato com poderes específicos para protestar e executar eventuais CCBs emitidas.</li>
    <li>11.2. O Tomador desde já autoriza expressamente a Tutu a compartilhar seus dados cadastrais e do empréstimo pretendido com as instituições financeiras e seus Investidores a fim de permitir a obtenção dos fundos para viabilização de seu empréstimo.</li>
</ul>
<ul>
    <li class="title">12. INADIMPLEMENTO DO TOMADOR</li>
    <li>12.1. Se, em algum momento, você acreditar que poderá não ser capaz de fazer um ou mais pagamentos devidos nos termos de um Contrato de Empréstimo, por favor contate-nos imediatamente. Se você perder um pagamento ou pagar apenas parcialmente o montante
        devido, nós entraremos em contato com você e lhe pediremos para explicar os motivos que levaram ao inadimplemento. Se nossa tentativa de cobrança falhar, dois dias após a data em que o pagamento era devido a sua conta será entendida como conta
        em atraso, ao passo que vamos continuar a tentar receber os valores devidos. Caso a situação de inadimplência persista por cinco dias ou mais, a Tutu poderá protestar e executar judicialmente as CCBs que lastrearam o empréstimo, além de quaisquer
        outras medidas extrajudiciais que julgar necessárias, incluindo a solicitação de inclusão do nome do Tomador e seus sócios/administradores no cadastro de maus pagadores.</li>
    <li>12.2. Caso o pagamento ainda não tenha sido feito no prazo estipulado em contrato, poderá ser cobrado do Tomador: (i) multa de 2% sobre o valor total emprestado e (ii) juros moratórios de 1% ao mês, além da correção do valor devido, mais despesas
        e eventuais honorários que tenha incorrido para fins de recuperação do valor devido e proteção dos investidores, ao passo que referidos valores deverão ser pagos em 2 (dois) dias úteis a contar do recebimento da respectiva cobrança.</li>
    <li>12.3. Eventuais atrasos ou descumprimentos por parte do Tomador poderão impactar negativamente em sua classificação de crédito para futuros empréstimos. Por favor, leia nossa política de privacidade para obter mais detalhes sobre como podemos divulgar
        informações sobre você.</li>
    <li>12.4. Em caso de inadimplemento, a Tutu poderá tomar as medidas judiciais e extrajudiciais para satisfação do débito dos Tomadores junto à Tutu e/ou aos Investidores, podendo, ainda, suspender ou bloquear o acesso do Tomador na Plataforma Tutu.</li>
</ul>
<ul>
    <li class="title">13. VENCIMENTO ANTECIPADO</li>
    <li>13.1. Além dos casos previstos em lei, o presente Contrato estará automática e antecipadamente vencido, com a imediata exigibilidade de todas as obrigações contraídas pelo Tomador no presente instrumento, independentemente de qualquer notificação,
        interpelação ou comunicação, judicial ou extrajudicial, nas seguintes hipóteses:</li>
    <li>(a) se o Tomador deixar de observar qualquer das suas obrigações previstas neste Contrato;</li>
    <li>(b) aplicação do valor do Empréstimo em finalidade diversa da informada na Plataforma;</li>
    <li>(c) alteração do objeto social do Tomador ou da natureza de seus negócios conforme conduzidos na data da submissão do pedido de Empréstimo na Plataforma;</li>
    <li>(d) alteração de dados, endereço ou quadro societário, sem a devida comunicação à Tutu;</li>
    <li>(e) alteração extinção, liquidação ou dissolução do Tomador, requerimento de autofalência, pedido de recuperação judicial ou extrajudicial formulado pelo Tomador ou pedido de falência do Tomador ou eventos análogos, independentemente do deferimento
        do respectivo pedido, bem como estar Tomador sujeito a qualquer forma de concurso de credores;</li>
    <li>(f) não obtenção, cancelamento, revogação, intervenção, extinção ou suspensão da concessão, autorizações, aprovações, registros e licenças, inclusive, as ambientais exigidas para a operação do Tomador ou para o desenvolvimento de seus projetos;</li>
    <li>(g) se houver fusão, cisão, incorporação, reorganização societária, transferência direta ou indireta do controle acionário ou qualquer forma de reorganização societária do Tomador, que não tenha sido prévia e expressamente aprovada pela Tutu;</li>
    <li>(h) não pagamento das parcelas do Empréstimo na respectiva data de vencimento ou vencimento antecipado de qualquer dívida e/ou obrigação financeira do Tomador; e</li>
    <li>(i) o protesto legítimo de títulos contra o Tomador, salvo se o protesto tiver sido efetuado por erro ou má fé de terceiros, desde que validamente comprovados pelo Tomador, ou, se for cancelado em qualquer hipótese, no prazo máximo de 72 (setenta
        e duas) horas contadas a partir da data da comunicação feita ao Tomador nesse sentido.</li>
</ul>
<ul>
    <li class="title">14. EXTINÇÃO DE CADASTRO TUTU</li>
    <li>14.1. Se você não quiser mais ser um Usuário da Plataforma e desde que não haja nenhum Contrato de Empréstimo em vigor, você poderá cancelar o seu cadastro diretamente na Plataforma ou, ainda, nos informar de sua intenção por e-mail, oportunidade
        em que providenciaremos o cancelamento em 5 (cinco) dias úteis a contar do recebimento da comunicação.</li>
    <li>14.2. A Tutu poderá, ainda, efetuar o cancelamento de seu cadastro e a sua exclusão da Plataforma nas seguintes hipóteses:</li>
    <li>(a) se você violar estes Termos ou os demais Temos e Condições de Uso e Política de Privacidade;</li>
    <li>(b) se você violar qualquer termo ou condição do Contrato de Empréstimo ou qualquer documento relacionado a este;</li>
    <li>(c) se o Tomador celebrar novo Contrato de Empréstimo sem garantia (ou qualquer acordo que vise à obtenção de financiamento) com terceiros, sempre que o montante total de financiamento com terceiros seja superior a 30% (trinta por cento) do montante
        total emprestado para o Tomador através da Plataforma, sem que tenha havido o prévio consentimento por escrito da Tutu;</li>
    <li>(d) se o Tomador outorgar quaisquer ativos ou garantias para terceiros após a data em que o Contrato de Empréstimo for celebrado sem o consentimento prévio e por escrito da Tutu;</li>
    <li>(e) se houver fundada suspeita que você cometeu fraude, foi envolvido em lavagem de dinheiro ou quaisquer outras atividades criminosas;</li>
    <li>(f) se você utilizar a Plataforma ou informação disponível na Plataforma em benefício de terceiros sem o consentimento prévio por escrito por parte da Tutu; ou</li>
    <li>(h) se você utilizar a Plataforma em qualquer uma das seguintes formas: (i) de modo que cause ou possa causar a interrupção, danificação ou qualquer outro efeito negativo à Plataforma ou a seu acesso; (ii) para fins fraudulentos, ou em conexão com
        uma atividade criminosa; (iii) para enviar, usar ou reutilizar qualquer material que seja ilegal, ofensivo, abusivo, indecente, difamatório, obsceno ou ameaçador; ou em violação de direito autoral, marca, privacidade ou qualquer outro direito;
        ou de qualquer outra forma prejudicial a terceiros; ou censurável; ou que consista em, ou contenha vírus, campanha política, solicitação comercial, correntes, mensagens em massa ou qualquer spam; ou (iv) para causar aborrecimento, inconveniência
        ou ansiedade desnecessária à Tutu e/ou seus usuários.</li>
    <li>14.3. O cancelamento de seu cadastro Tutu não irá resultar em rescisão de qualquer Contrato de Empréstimo do qual você seja parte nesse momento. Ou seja, você permanecerá obrigado a cumprir com o Contrato de Empréstimo.</li>
    <li>14.4. No caso de cancelamento de seu cadastro Tutu, vamos creditar na conta bancária ou de pagamento indicada no seu cadastro os fundos restantes na sua conta Investidor ou Tomador Tutu, em 5 (cinco) dias úteis a contar do cancelamento de seu cadastro
        Tutu.
    </li>
</ul>
<ul>
    <li class="title">15. CONDIÇÕES GERAIS</li>
    <li>15.1 As informações fornecidas na Plataforma são direcionadas exclusivamente ao uso por pessoas e entidades que atendam aos critérios estabelecidos nas cláusulas acima e não se destinam à distribuição ou uso por qualquer pessoa ou entidade que não
        para os fins estipulados na Plataforma.</li>
    <li>15.2. É permitido baixar e imprimir conteúdo a partir da Plataforma exclusivamente para seu uso pessoal ou no curso de seu negócio, na medida necessária para utilizar os serviços prestados nesta Plataforma. O conteúdo disponibilizado na Plataforma
        não pode ser copiado ou reproduzido, modificado, redistribuído ou utilizado de qualquer maneira ou para qualquer outro fim sem o nosso consentimento prévio e expresso por escrito.</li>
    <li>15.3. Nós não somos responsáveis pelo conteúdo baixado ou postado por investidores ou Tomadores na Plataforma. A Tutu não irá editar o conteúdo baixado ou postado na Plataforma.</li>
    <li>15.4. Faremos todo o possível para permitir o acesso ininterrupto à Plataforma. No entanto, o acesso à Plataforma poderá, eventualmente, ser suspenso ou restringido, a qualquer momento.</li>
    <li>15.5. Você, desde já, concorda em usar a Plataforma apenas para fins legais e de forma a não infringir direitos de terceiros ou restringir ou inibir o uso por parte de qualquer outro usuário da Plataforma.</li>
    <li>15.6. Você declara que tomou todas as precauções razoáveis para garantir que todos os dados enviados ou submetidos à Plataforma estão livres de vírus ou qualquer outro software malicioso que possa causar efeitos danosos à Plataforma ou seus usuários.</li>
    <li>15.7. O conteúdo e o material disponíveis na Plataforma são apenas para fins informativos e não devem ser considerados como uma oferta, solicitação, convite, aconselhamento ou recomendação de compra ou venda de investimentos, títulos ou quaisquer
        outros serviços financeiros ou produto bancário. Se você não tiver certeza se um produto é adequado ao seu perfil, você deve entrar em contato com um consultor financeiro independente.</li>
    <li>15.8. Você é responsável por todos os custos eventualmente incorridos por você em razão do acesso à Plataforma.</li>
    <li>15.9. Nós não assumimos qualquer responsabilidade por seu uso da Plataforma, sendo que tal uso é inteiramente por sua conta e risco. Enquanto tomamos precauções razoáveis para evitar a existência de vírus ou outros programas maliciosos na Plataforma,
        não assumimos qualquer responsabilidade caso estes venham a existir na Plataforma. É de sua responsabilidade usar, atualizar e manter o software antivírus apropriado para o seu computador.</li>
    <li>15.10. A informação transmitida através da Plataforma passa por redes públicas de telecomunicações. Nós não temos qualquer responsabilidade se as comunicações enviadas por meio da Plataforma forem interceptadas por terceiros ou incorretamente entregues
        ou não entregues.</li>
    <li>15.11. A Plataforma pode conter links para sites de terceiros. Nós não aceitamos nenhuma responsabilidade ou obrigação por qualquer material fornecido por ou contido em qualquer site de terceiros, cujo link seja disponibilizado na Plataforma ou qualquer
        uso de dados pessoais por terceiros a partir de tais links.</li>
</ul>
<ul>
    <li class="title">16. PRIVACIDADE</li>
    <li>16.1. Você concorda que toda e qualquer informação pessoal ou da empresa que você venha a fornecer para nós através desta plataforma pode ser tratada, recolhida, armazenada, processada e utilizada de acordo com a nossa política de privacidade atual,
        a qual atende ao disposto na Lei nº 14.709, de 14 de agosto de 2018.</li>
</ul>
<ul>
    <li class="title">17. DIREITOS DE PROPRIEDADE INTELECTUAL</li>
    <li>17.1. Nós possuímos todos os direitos autorais e de propriedade intelectual, presentes e futuros, da Plataforma Tutu, das marcas registradas e não registradas, direitos de design registrados ou não registrados, direitos de banco de dados e todos os
        outros direitos de propriedade intelectual em relação à Plataforma Tutu.</li>
    <li>17.2. Se e na medida em que eventuais direitos de propriedade intelectual da sua titularidade, por força de lei ou de qualquer outra forma, venham a ser veiculados em nossa Plataforma, você concorda em nos autorizar, bem como a terceiros por nós autorizados,
        a utilizar e veicular essa propriedade intelectual em nossa Plataforma.</li>
    <li>17.3. Você irá manter a sua propriedade sobre todos os direitos de propriedade intelectual sobre os dados que você submeter à Plataforma Tutu. Você concede-nos o direito exclusivo, livre de royalties, licença por prazo indeterminado, em todo o mundo,
        para usar, copiar, distribuir, publicar e transmitir esses dados de qualquer maneira relacionada à nossa Plataforma.</li>
    <li>17.4. A Tutu vai envidar seus melhores esforços para garantir a segurança dos dados disponibilizados, todavia a Tutu não se responsabiliza pela veracidade destes ou por sua guarda e manutenção.</li>
</ul>
<ul>
    <li class="title">18. DO USO DA IMAGEM DO TOMADOR</li>
    <li>18.1. O Tomador autoriza a Tutu a utilizar seu nome completo e fotografia de perfil de redes sociais (nome e fotografia, conjuntamente “Perfil”) para divulgar a demanda de crédito da empresa aos Investidores, no âmbito da Plataforma Tutu.</li>
</ul>
<ul>
    <li class="title">19. DA RESPONSABILIDADE DOS USUÁRIOS</li>
    <li>19.1. Você será responsável perante a Tutu por qualquer perda ou dano sofrido por nós como resultado de qualquer violação destes termos e condições; qualquer contrato de Empréstimo realizado por você ou qualquer uso fraudulento da Plataforma Tutu.</li>
</ul>
<ul>
    <li class="title">20. DA RESPONSABILIDADE DA TUTU</li>
    <li>20.1. A Tutu não assume qualquer responsabilidade por eventuais perdas ou danos que possam vir a ser sofridos a partir da participação do Investidor ou Tomador da Plataforma ou da utilização dos serviços fornecidos através da Plataforma, exceto quando
        tal perda ou dano decorra da nossa violação destes Termos e Condições do Investidor e Tomador ou seja causado por negligência, dolo ou fraude por parte da Tutu ou nossos funcionários. Nós não somos responsáveis por qualquer violação destes Termos
        e Condições do Investidor e Tomador decorrentes de circunstâncias fora de nosso controle.</li>
</ul>
<ul>
    <li class="title">21. CONTATO TUTU</li>
    <li>21.1. Se você tiver quaisquer dúvidas sobre estes Termos e Condições do Investidor e Tomador, por favor, entre em contato através do e-mail quero@tutudigital.com.br ou por meio do site da Tutu: www.tutudigital.com.br.</li>
    <li>21.2. Se você quiser fazer uma reclamação formal, poderá fazê-lo por escrito, por correio (Avenida Paulista, nº 1.374, 11º andar) ou por e-mail (quero@tutudigital.com.br ou admin@tutudigital.com.br). A reclamação poderá ser feita, ainda, por telefone,
        no número (11) 3280-0009.</li>
</ul>
<ul>
    <li class="title">22. AS ALTERAÇÕES DESTES TERMOS E CONDIÇÕES</li>
    <li>22.1. Poderemos atualizar ou alterar unilateralmente estes Termos e Condições do Investidor e Tomador de tempos em tempos, a fim de cumprir a legislação aplicável ou de satisfazer as nossas mudanças de negócios. Eventuais alterações serão previamente
        informadas por e-mail e publicadas na Plataforma. Ao continuar a utilizar a Plataforma, você concorda em permanecer vinculado aos Termos atualizados e/ou alterados.</li>
</ul>
<ul>
    <li class="title">23. OUTROS TERMOS IMPORTANTES</li>
    <li>23.1. A nulidade ou invalidade de qualquer das disposições destes Termos e Condições do Investidor e Tomador não prejudicará a validade e eficácia das demais cláusulas dos Termos e Condições de Uso, Política de Privacidade ou do Contrato de Empréstimo.
        Caso alguma decisão judicial pronuncie-se sobre a invalidade ou ineficácia de qualquer das disposições destes Termos e Condições do Investidor e Tomador, as Partes deverão substituir a regra inquinada por outra que, sendo lícita, permita sejam
        alcançados, na maior extensão possível, os resultados práticos inicialmente visados.</li>
    <li>23.2. Todas as renúncias, indenizações e exclusões nestes Termos e Condições do Investidor e Tomador sobreviverão à rescisão do acordo entre nós, por qualquer razão.</li>
    <li>23.3. Podemos, no todo ou em parte, transigir, renunciar ou adiar, na nossa absoluta discrição, qualquer responsabilidade devida a nós ou direito concedido a nós nestes Termos e Condições, sem de modo algum prejudicá-los ou afetá-los.</li>
    <li>23.4. As Partes reconhecem que o não exercício, por qualquer delas, ou o atraso no exercício de qualquer direito que lhes seja assegurado por este instrumento ou por lei, não constituirá novação ou renúncia de tal direito, nem prejudicará o seu eventual
        exercício, a qualquer tempo. A renúncia, por qualquer das Partes, de algum dos direitos ou poderes previstos neste instrumento somente será válida se formalizada por escrito.</li>
    <li>23.5. Estes Termos, os Termos e Condições de Uso, a Política de Privacidade e os Contratos de Empréstimo estabelecem a totalidade do acordo entre você e nós com relação ao seu uso da Plataforma, sendo que estes documentos substituem todas e quaisquer
        representações, comunicações e acordos anteriores (escrito ou oral) feito por você ou por nós.</li>
    <li>23.6. A Tutu poderá ceder, transferir, novar ou não exercer qualquer dos seus direitos ao abrigo destes, por si só ou através de qualquer empresa ou outra entidade legal que esteja sob o controle ou participação acionária da Tutu.</li>
    <li>25.2. Os Usuários ficam cientes que a relação havida entre a Tutu e os Usuários não se trata de relação de consumo, não sendo aplicável o Código de Defesa do Consumidor. Isso porque a Tutu apenas intermedia a relação haviada entre os Investidores
        e Tomadores, e tem como objetivo operacionalizar o empréstimo para pessoas jurídicas, como insumo empresarial</li>
    <li>23.7. Estes Termos são regidos pelas leis da República Federativa do Brasil.</li>
</ul>
<ul>
    <li class="title">24. ASSINATURA ELETRONICA</li>
    <li>24.1. Os Usuários admitem como válidas as assinaturas eletrônicas por elas realizadas no Contrato de Empréstimo e em qualquer outro termo, contrato ou documento vinculado à Tutu e à Plataforma Tutu, nos termos do artigo 10, §2º, da Medida Provisória
        n.º 2.200-2, de 24 de agosto de 2001, sendo tais documentos dotados de certeza, liquidez e exigibilidade (título executivo extrajudicial), independetemente da assinatura de testemunhas.</li>

</ul>
<ul>
    <li class="title">25. FORO</li>
    <li>25.1. Fica eleito o Foro Central da Comarca de São Paulo, Estado de São Paulo, ressalvado à Tutu o direito de optar pelo foro de domicílio do Usuário para dirimir quaisquer questões oriundas destes Termos e Condições do Investidor e Tomador.</li>
    <li>Assim, sendo, o Usuário declara e concorda expressamente com o inteiro teor destes, ficando desde já obrigado e sujeito aos direitos e obrigações oriundos deste instrumento.</li>
</ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'terms-business-investor',
  methods: {
    close () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative h-120 w-full bg-white z-50 flex flex-col pb-6 pt-8 rounded-lg
  @screen lg
    @apply w-11/12 h-160

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2 text-lg

.content_modal
  @apply flex flex-col w-full px-4 overflow-auto
  @screen lg
    @apply px-8

.row
  @apply w-full flex justify-center
  @screen md
    @apply justify-center mt-6

.title
  @apply text-xl text-primary font-bold mb-3

ul li
  @apply leading-normal mb-2 text-justify

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>
